
import { Component, Vue } from "vue-property-decorator";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main.vue";
import * as API_Supplement from "@/api/supplement";
import { mergeSkuList } from "@/utils";

@Component({
  name: "supplementList",
  components: {
    EnTableLayout
  }
})

export default class supplementList extends Vue {
  loading = false;
  tableData: any = [];
  params: any = {
    page_no: 1,
    page_size: 20,
    repair_order: 1
  }

  /** 高级搜索数据 */
  advancedForm: any = {
    order_status: "ALL",
    order_time_range: [],
    repair_order_show: '',
    mall_type: '',
    trade_type: '',
  };
  search_type = "order_sn";
  pageData = {};
  tradeformFilter(status) {
    const statusMap = {
      1: "普通商品订单",
      2: "礼包订单",
      3: "计次卡订单",
      4: "宅配卡订单"
    }
    return statusMap[status];
  }
  mounted() {
    this.getSupplementList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);

  }

  activated() {
    this.getSupplementList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  }
  filterShop(mall_type) {
    switch (mall_type) {
      case 4:
        return "智能套餐卡商城 ";
      case 5:
        return "双选商城";
      case 6:
        return "小程序商城";
      default:
        return "未知";
    }
  }

  orderSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex >= 4 && columnIndex <= 12) {
      return {
        rowspan: 1,
        colspan: 1
      };
    } else {
      return row.span;
    }
  }

  /** 分页大小发生改变 */
  handlePageSizeChange(size) {
    this.params.page_no = 1;
    this.params.page_size = size;
    this.getSupplementList();
  }

  /** 分页页数发生改变 */
  handlePageCurrentChange(page) {
    this.params.page_no = page;
    this.getSupplementList();
  }

  /** 高级搜索事件触发 */
  advancedSearchEvent() {
    // 初始化搜索条件
    delete this.params.order_sn;
    delete this.params.buyer_name;
    delete this.params.goods_name;
    delete this.params.ship_no;
    delete this.params.ship_mobile;
    delete this.params.card_code;
    delete this.params.card_number;
    delete this.params.shop_name;

    let obj = {};
    if (this.search_type) {
      obj[this.search_type] = this.advancedForm.keywords;
    }

    this.params = {
      ...this.params,
      ...this.advancedForm,
      ...obj
    };

    delete this.params.start_time;
    delete this.params.end_time;
    if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length) {
      this.params.start_time =
        new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
      this.params.end_time =
        new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
        86400;
    }
    delete this.params.keywords;
    delete this.params.order_time_range;
    this.params.page_no = 1;
    this.getSupplementList();
  }

  getSupplementList() {
    this.loading = true;
    let params = {
      ...this.params
    }
    if (params.order_status == "WAIT_SHIP") params.order_status = "PAID_OFF"
    API_Supplement.getSupplementList(params).then(res => {
      console.log(res)
      this.loading = false
      this.pageData = res;
      this.tableData = mergeSkuList(res.data, 1);
    })
  }

  /** 查看详情 */
  handleOperateOrder(row) {
    this.$store.dispatch("addVisitedViews", this.$route);
    this.$router.push({
      path: `/order/supplement-detail/${row.trade_sn}`
    });
  }
}
